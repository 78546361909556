import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../Utils/api";
import axios from "axios";

const initialState = {
  loading: false,
  error: null,
  commissions: [],
  currentCommission: null,
  commissionSummary: null,
};

// Get All Commissions
export const getAllCommissions = createAsyncThunk(
  "commission/getAllCommissions",
  async () => {
    const token = localStorage.getItem("auth-token");
    try {
      const response = await axios({
        method: "GET",
        url: `${baseUrl}/commission/all`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

// Get Commission Details
export const getCommissionDetails = createAsyncThunk(
  "commission/getCommissionDetails",
  async (commissionId) => {
    const token = localStorage.getItem("auth-token");
    try {
      const response = await axios({
        method: "GET",
        url: `${baseUrl}/commission/${commissionId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const getCommissionSummary    = createAsyncThunk(
  "commission/getCommissionSummary",
  async () => {
    const token = localStorage.getItem("auth-token");
    try {
      const response = await axios({
        method: "GET",
        url: `${baseUrl}/commission/summary`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
)

const commissionSlice = createSlice({
  name: "commission",
  initialState,
  reducers: {
    clearCommissionError: (state) => {
      state.error = null;
    },
  },
  extraReducers(builder) {
    // Get All Commissions
    builder.addCase(getAllCommissions.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllCommissions.fulfilled, (state, action) => {
      state.loading = false;
      if(action.payload?.result){
        state.commissions = action.payload?.result;
      }
    });
    builder.addCase(getAllCommissions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // Get Commission Details
    builder.addCase(getCommissionDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCommissionDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.currentCommission = action.payload;
    });
    builder.addCase(getCommissionDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
        });

    // Get Commission Summary
    builder.addCase(getCommissionSummary.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCommissionSummary.fulfilled, (state, action) => {
      state.loading = false;
      state.commissionSummary = action.payload;
    });
    builder.addCase(getCommissionSummary.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    
    
  },
});

export const { clearCommissionError } = commissionSlice.actions;
export default commissionSlice.reducer; 