import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../Utils/api";
import axios from "axios";

const initialState = {
  formData: {},
  loading: false,
  summaryData: {},
  steps: [],
  quotationData: {},
  quotationFormLoading: false,
  calculatePremiumLoading: false,
  discountLoading: false,
  discountError: null,
  discountData: null,
  fileUploadLoading: false,
  fileUploadError: null,
};
const token = localStorage.getItem("auth-token");

export const fetchQoutationForm = createAsyncThunk(
  "fetchQoutationForm",
  async () => {
    try {
      const response = await axios({
        method: "GET",
        url: `${baseUrl}/quotation/form`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

export const calculateQuotationData = createAsyncThunk(
  "calculateQuotationData",
  async (quotationData) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${baseUrl}/quotation/calculate`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(quotationData),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

export const saveQuote = createAsyncThunk(
  "saveQuote",
  async (quotationData) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${baseUrl}/quotation`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(quotationData),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

export const ammendQuote = createAsyncThunk(
  "saveQuote",
  async (quotationData) => {
    const data = { ...quotationData };
    delete data.id;
    delete data.created_at;
    delete data.updated_at;
    delete data.quotation_status;
    data.discount = 0;
    try {
      const response = await axios({
        method: "PUT",
        url: `${baseUrl}/quotation/${quotationData?.id}/ammend`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchDiscounts = createAsyncThunk("fetchDiscounts", async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/quotation/discounts`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    console.log(error);
  }
});

export const createManualUnderwriting = createAsyncThunk(
  "createManualUnderwriting",
  async (underwritingData) => {
    const token = localStorage.getItem("auth-token");
    try {
      const response = await axios({
        method: "POST",
        url: `${baseUrl}/quotation/writing`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(underwritingData),
      });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const getAllManualUnderwriting = createAsyncThunk(
  "getAllManualUnderwriting",
  async () => {
    const token = localStorage.getItem("auth-token");
    try {
      const response = await axios({
        method: "GET",
        url: `${baseUrl}/quotation/writing/all`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const getManualUnderwritingDetails = createAsyncThunk(
  "getManualUnderwritingDetails",
  async (id) => {
    const token = localStorage.getItem("auth-token");
    try {
      const response = await axios({
        method: "GET",
        url: `${baseUrl}/quotation/writing/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const getManualUnderwritingDetailsActivity = createAsyncThunk(
  "getManualUnderwritingDetailsActivity",
  async (id) => {
    const token = localStorage.getItem("auth-token");
    try {
      const response = await axios({
        method: "GET",
        url: `${baseUrl}/quotation/writing/activity/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const proceedManualUnderwriting = createAsyncThunk(
  "proceedManualUnderwriting",
  async ({ id, remarks }) => {
    const token = localStorage.getItem("auth-token");
    try {
      const response = await axios({
        method: "POST",
        url: `${baseUrl}/quotation/writing/activity/${id}?remarks=${encodeURIComponent(
          remarks
        )}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: "",
      });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const requestQuotationDiscount = createAsyncThunk(
  "requestQuotationDiscount",
  async ({ quotationId, discountData }) => {
    const token = localStorage.getItem("auth-token");
    try {
      const response = await axios({
        method: "POST",
        url: `${baseUrl}/quotation/discount/${quotationId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(discountData),
      });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const updateQuotationDiscount = createAsyncThunk(
  "updateQuotationDiscount",
  async ({ quotationId, discountData }) => {
    const token = localStorage.getItem("auth-token");
    console.log(discountData);
    try {
      const response = await axios({
        method: "PATCH",
        url: `${baseUrl}/quotation/discount/${quotationId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(discountData),
      });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const uploadFile = createAsyncThunk("uploadFile", async (file) => {
  const token = localStorage.getItem("auth-token");
  try {
    const formData = new FormData();
    formData.append("files", file);

    const response = await axios({
      method: "POST",
      url: `${baseUrl}/common/file-upload`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export const approveManualQuote = createAsyncThunk(
  "quotation/approveManualQuote",
  async (quoteId) => {
    const token = localStorage.getItem("auth-token");
    try {
      const response = await axios({
        method: "POST",
        url: `${baseUrl}/quotation/writing/${quoteId}/status?status=ACCEPT`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error approving manual quote:", error);
      throw error;
    }
  }
);

export const addManualQuoteComment = createAsyncThunk(
  "quotation/addManualQuoteComment",
  async ({ quoteId, remarks, file }) => {
    const token = localStorage.getItem("auth-token");
    try {
      const formData = new FormData();
      formData.append("remarks", remarks);
      if (file) {
        formData.append("file", file);
      }

      const response = await axios({
        method: "POST",
        url: `${baseUrl}/quotation/writing/activity/${quoteId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });
      return response.data;
    } catch (error) {
      console.error("Error adding comment:", error);
      throw error;
    }
  }
);

export const fetchManualProducts = createAsyncThunk(
  "quotation/fetchManualProducts",
  async () => {
    const token = localStorage.getItem("auth-token");
    try {
      const response = await axios({
        method: "GET",
        url: `https://edeneasyclaim.com/integrations/products/EDCK`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error adding comment:", error);
      throw error;
    }
  }
);

export const sendManualQuoteEmail = createAsyncThunk(
  "quotation/sendManualQuoteEmail",
  async (quoteId) => {
    const token = localStorage.getItem("auth-token");
    try {
      const response = await axios({
        method: "POST",
        url: `${baseUrl}/quotation/email/${quoteId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error adding comment:", error);
      throw error;
    }
  }
);

export const sendManualQuoteEmail2 = createAsyncThunk(
  "quotation/sendManualQuoteEmail2",
  async ({ quotationId, activityId }) => {
    const token = localStorage.getItem("auth-token");
    try {
      const response = await axios({
        method: "POST",
        url: `${baseUrl}/quotation/writing/activity/email/${quotationId}/${activityId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error adding comment:", error);
      throw error;
    }
  }
);

const quotationSlice = createSlice({
  name: "quotationSlice",
  initialState: initialState,
  reducers: {
    editQuote: (state, payload) => {
      const current = payload.payload.current;
      const stepsArr = [];
      if (current.indexOf("customer") !== -1) stepsArr.push(1);
      if (current.indexOf("location") !== -1) stepsArr.push(2);
      if (current.indexOf("benefits") !== -1) stepsArr.push(3);
      if (current.indexOf("members") !== -1) stepsArr.push(4);
      if (current.indexOf("copay") !== -1) stepsArr.push(5);
      state.steps = stepsArr;
      state.quotationData = payload.payload.quotationRequest;
      console.log(state.quotationData);
    },
    removeEditQuote: (state) => {
      state.quotationData = {};
      state.steps = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchQoutationForm.pending, (state) => {
      state.loading = true;
      state.fetchQoutationForm = true;
    });
    builder.addCase(fetchQoutationForm.fulfilled, (state, action) => {
      state.loading = false;
      state.fetchQoutationForm = false;
      state.formData = action?.payload?.data;
    });
    builder.addCase(fetchQoutationForm.rejected, (state, action) => {
      state.loading = false;
      state.fetchQoutationForm = false;
    });
    builder.addCase(calculateQuotationData.pending, (state) => {
      state.loading = true;
      state.calculatePremiumLoading = true;
    });
    builder.addCase(calculateQuotationData.fulfilled, (state, action) => {
      state.loading = false;
      state.calculatePremiumLoading = false;
      state.summaryData = action?.payload?.data;
    });
    builder.addCase(calculateQuotationData.rejected, (state, action) => {
      state.calculatePremiumLoading = false;
    });

    builder.addCase(saveQuote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveQuote.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(saveQuote.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fetchDiscounts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDiscounts.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fetchDiscounts.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(createManualUnderwriting.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createManualUnderwriting.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createManualUnderwriting.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getAllManualUnderwriting.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllManualUnderwriting.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getAllManualUnderwriting.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getManualUnderwritingDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getManualUnderwritingDetails.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getManualUnderwritingDetails.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(proceedManualUnderwriting.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(proceedManualUnderwriting.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(proceedManualUnderwriting.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(requestQuotationDiscount.pending, (state) => {
      state.discountLoading = true;
      state.discountError = null;
    });
    builder.addCase(requestQuotationDiscount.fulfilled, (state, action) => {
      state.discountLoading = false;
      state.discountData = action.payload?.data;
    });
    builder.addCase(requestQuotationDiscount.rejected, (state, action) => {
      state.discountLoading = false;
      state.discountError = action.error.message;
    });

    builder.addCase(updateQuotationDiscount.pending, (state) => {
      state.discountLoading = true;
      state.discountError = null;
    });
    builder.addCase(updateQuotationDiscount.fulfilled, (state, action) => {
      state.discountLoading = false;
      state.discountData = action.payload?.data;
    });
    builder.addCase(updateQuotationDiscount.rejected, (state, action) => {
      state.discountLoading = false;
      state.discountError = action.error.message;
    });

    builder.addCase(uploadFile.pending, (state) => {
      state.fileUploadLoading = true;
      state.fileUploadError = null;
    });
    builder.addCase(uploadFile.fulfilled, (state, action) => {
      state.fileUploadLoading = false;
    });
    builder.addCase(uploadFile.rejected, (state, action) => {
      state.fileUploadLoading = false;
      state.fileUploadError = action.error.message;
    });

    builder.addCase(approveManualQuote.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(approveManualQuote.fulfilled, (state, action) => {
      state.loading = false;
      if (state.manualQuotes) {
        const quoteIndex = state.manualQuotes.findIndex(
          (quote) => quote.id === action.meta.arg
        );
        if (quoteIndex !== -1) {
          state.manualQuotes[quoteIndex].status = "ACCEPT";
        }
      }
    });
    builder.addCase(approveManualQuote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(addManualQuoteComment.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addManualQuoteComment.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addManualQuoteComment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getManualUnderwritingDetailsActivity.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getManualUnderwritingDetailsActivity.fulfilled,
      (state, action) => {
        state.loading = false;
      }
    );
    builder.addCase(
      getManualUnderwritingDetailsActivity.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );
    builder.addCase(fetchManualProducts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchManualProducts.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fetchManualProducts.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(sendManualQuoteEmail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendManualQuoteEmail.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(sendManualQuoteEmail.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(sendManualQuoteEmail2.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendManualQuoteEmail2.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(sendManualQuoteEmail2.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { editQuote, removeEditQuote } = quotationSlice.actions;

export default quotationSlice.reducer;
