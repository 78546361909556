import ManualQuoteRequest from "../../Components/ManualUnderwriting/ManualQuoteRequest";
import Main from "../../Components/Common/Template/Index";

export default function ManualUnderwriting() {
  return (
    <Main pageName="Client Information">
      <ManualQuoteRequest />
    </Main>
  );
}
