import SideBar from "./Sidebar";
import Header from "./Header";
import styles from "./index.module.css";
import PageBreadcrumb from "../Breadcrumb";
import { useState } from "react";

function Main({ children, pageName, showHeader = true }) {
  const [isDrawerOpen, setToggleDrawer] = useState(false);

  return (
    <div className={styles.pageContainer}>
      <SideBar />

      <div className={styles.contentContainer}>
        {showHeader && (
          <>
            <Header pageName={pageName} setToggleDrawer={setToggleDrawer} />
          </>
        )}
        {children}
      </div>
    </div>
  );
}

export default Main;
