import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createManualUnderwriting,
  uploadFile,
} from "../../Store/quotationSlice";
import {
  Form,
  Input,
  Select,
  Button,
  Typography,
  Upload,
  Space,
  Card,
  Row,
  Col,
  Checkbox,
  Radio,
  message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import PageBreadcrumb from "../Common/Breadcrumb";
import { fetchManualProducts } from "../../Store/quotationSlice";

const { Title } = Typography;
const { TextArea } = Input;

const MEMBER_TEMPLATE_URL =
  "https://eden-static-files.s3.ap-south-1.amazonaws.com/templates/Member-Information-Template.xlsx";

const NATURE_OF_BUSINESS_OPTIONS = [
  "Agriculture & Agribusiness",
  "Automotive & Transport Services",
  "Construction & Engineering",
  "Education & Training",
  "Energy & Utilities (Electricity, Water, Oil & Gas, Renewable Energy)",
  "Financial Services (Banking, Insurance, Investment, Fintech)",
  "Healthcare & Pharmaceuticals",
  "Hospitality & Tourism",
  "Manufacturing & Processing",
  "Media & Entertainment",
  "Mining & Natural Resources",
  "Non-Governmental Organizations (NGOs) & Non-Profits",
  "Professional Services (Law, Consulting, Accounting, HR, Marketing)",
  "Public Sector & Government Agencies",
  "Real Estate & Property Management",
  "Security Services (Private Security, Risk Consulting, Investigations)",
  "Sports & Fitness",
  "Technology & Telecommunications",
  "Transportation & Logistics",
  "Wholesale & Retail Trade",
];

const LOCATION_OPTIONS = [
  "Mombasa",
  "Kwale",
  "Kilifi",
  "Tana River",
  "Lamu",
  "Taita Taveta",
  "Garissa",
  "Wajir",
  "Mandera",
  "Marsabit",
  "Isiolo",
  "Meru",
  "Tharaka Nithi",
  "Embu",
  "Kitui",
  "Machakos",
  "Makueni",
  "Nyandarua",
  "Nyeri",
  "Kirinyaga",
  "Murang'a",
  "Kiambu",
  "Turkana",
  "West Pokot",
  "Samburu",
  "Trans Nzoia",
  "Uasin Gishu",
  "Elgeyo Marakwet",
  "Nandi",
  "Baringo",
  "Laikipia",
  "Nakuru",
  "Narok",
  "Kajiado",
  "Kericho",
  "Bomet",
  "Kakamega",
  "Vihiga",
  "Bungoma",
  "Busia",
  "Siaya",
  "Kisumu",
  "Homa Bay",
  "Migori",
  "Kisii",
  "Nyamira",
  "Nairobi City",
];

const PRODUCT_OPTIONS = ["SME Product Eden Care Medical"];

const FUNDING_ARRANGEMENT_OPTIONS = ["Insured", "Fund Managed", "Hybrid"];

const AGGREGATION_LEVEL_OPTIONS = ["Shared Limit", "Non-Shared Limit"];

const SERVICE_PROVIDER_OPTIONS = ["Tier 1", "Tier 2", "Tier 3"];

const ManualQuoteRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.quotationSlice.loading);
  const fileUploadLoading = useSelector(
    (state) => state.quotationSlice.fileUploadLoading
  );
  const [form] = Form.useForm();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [messageApi, messageContextHolder] = message.useMessage();
  const [products, setProducts] = useState([]);

  const handleFileUpload = async (file, fieldName) => {
    try {
      const response = await dispatch(uploadFile(file)).unwrap();
      // Assuming the API returns the file URL in response.data
      console.log(response.data.result?.[0]?.url);
      form.setFieldsValue({
        [fieldName]: {
          url: response.data.result?.[0]?.url,
          file_name: response.data.result?.[0]?.file_name,
        },
      });
      return false; // Prevent default upload
    } catch (error) {
      console.error("File upload failed:", error);
      return false;
    }
  };

  const uploadProps = {
    beforeUpload: (file, fileList) => {
      return false; // Prevent default upload
    },
    onChange: async (info, fieldName) => {
      if (info.file.status !== "uploading") {
        await handleFileUpload(info.file, fieldName);
      }
    },
  };

  useEffect(() => {
    const fetch = async () => {
      const res = await dispatch(fetchManualProducts());
      const products = res.payload.map((p) => p.name);
      setProducts(products);
    };
    fetch();
  }, []);

  const handleSubmit = async (values) => {
    try {
      // Transform benefits data
      let benefits_limits_and_aggregation = [];
      if (values.benefits_limits_and_aggregations) {
        benefits_limits_and_aggregation = selectedCategories.map((category) => {
          const categoryData =
            values.benefits_limits_and_aggregations[category];
          return {
            scheme_category: category,
            copay: categoryData.copay
              ? parseInt(categoryData.copay.replace(/,/g, ""))
              : 0,

            service_provider_access: categoryData.service_provider_access || [],
            benefits: [
              {
                benefit_name: "Dental",
                benefit_limit: categoryData.dental_limit
                  ? parseInt(categoryData.dental_limit.replace(/,/g, ""))
                  : "0",
                aggregation_level:
                  categoryData.dental_aggregation || "Non-Shared Limit",
              },
              {
                benefit_name: "Inpatient",
                benefit_limit: categoryData.inpatient_limit
                  ? parseInt(categoryData.inpatient_limit.replace(/,/g, ""))
                  : "0",
                aggregation_level:
                  categoryData.inpatient_aggregation || "Shared Limit",
              },
              {
                benefit_name: "Optical",
                benefit_limit: categoryData.optical_limit
                  ? parseInt(categoryData.optical_limit.replace(/,/g, ""))
                  : "0",
                aggregation_level:
                  categoryData.optical_aggregation || "Shared Limit",
              },
              {
                benefit_name: "Outpatient",
                benefit_limit: categoryData.outpatient_limit
                  ? parseInt(categoryData.outpatient_limit.replace(/,/g, ""))
                  : "0",
                aggregation_level:
                  categoryData.outpatient_aggregation || "Non-Shared Limit",
              },
            ],
          };
        });
      }

      // Construct final payload
      const finalValues = {
        business_name: values.business_name,
        client_budget: values.client_budget
          ? parseInt(values.client_budget.replace(/,/g, ""))
          : 0,
        registration_number: values.registration_number,
        pin_number: values.pin_number,
        region: "Kenya",
        business_email: values.business_email,
        nature_of_business: values.nature_of_business,
        location_of_business: values.location_of_business,
        location_of_majority_insured_members:
          values.location_of_majority_insured_members,
        size_of_company: values.size_of_company?.toString(),
        funding_arrangement: values.funding_arrangement,
        product: values.product,
        aggregation_level: values.aggregation_level,
        benefits_limits_and_aggregation,
        claim_report: values.claim_report?.file
          ? {
              url: values.claim_report.url,
              file_name: values.claim_report.file.name,
            }
          : undefined,
        member_information_template: values.member_information_template?.file
          ? {
              url: values.member_information_template.url,
              file_name: values.member_information_template.file.name,
            }
          : undefined,
      };

      // Remove any undefined values
      Object.keys(finalValues).forEach(
        (key) => finalValues[key] === undefined && delete finalValues[key]
      );

      const response = await dispatch(
        createManualUnderwriting(finalValues)
      ).unwrap();
      localStorage.setItem("quoteTab", "manual");
      messageApi.success("Quote request submitted successfully");
      form.resetFields();
      navigate("/quotes");
    } catch (error) {
      console.error("Failed to submit:", error);
      messageApi.error("Failed to submit quote request");
    }
  };

  const handleCategoryChange = (e) => {
    if (selectedCategories.includes(e.target.value)) {
      setSelectedCategories(
        selectedCategories.filter((category) => category !== e.target.value)
      );
    } else {
      setSelectedCategories([...selectedCategories, e.target.value]);
    }
  };



  return (
    <div style={{ padding: 10 }}>
      {messageContextHolder}
      <Card>
        <Title level={4}> Request for Underwriter Quote</Title>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            region: "Kenya",
            funding_arrangement: "Insured",
            aggregation_level: "Non-Shared Limit",
          }}
        >
          <Card title="Client & Business Details" style={{ marginBottom: 24 }}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="business_name"
                  label="Business Name"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="ABC Logistics Ltd" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="business_email"
                  label="Business Email"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Enter the email" type="email" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="registration_number"
                  label="Business Registration Number"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="BRN-0012345" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="pin_number"
                  label="PIN Number"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="P123456789A" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="nature_of_business"
                  label="Nature of Business"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Select business type">
                    {NATURE_OF_BUSINESS_OPTIONS.map((option) => (
                      <Select.Option key={option} value={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="location_of_business"
                  label="Location of Business"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Select a location" showSearch>
                    {LOCATION_OPTIONS.map((option) => (
                      <Select.Option key={option} value={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="location_of_majority_insured_members"
                  label="Location of Majority Insured Members"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Select a location" showSearch>
                    {LOCATION_OPTIONS.map((option) => (
                      <Select.Option key={option} value={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="size_of_company"
                  label="Size of Company"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Enter the size" type="number" />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card title="Insurance Cover Details" style={{ marginBottom: 24 }}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="funding_arrangement"
                  label="Funding Arrangement"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Select funding arrangement">
                    {FUNDING_ARRANGEMENT_OPTIONS.map((option) => (
                      <Select.Option key={option} value={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="product"
                  label="Product"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Select product">
                    {products.map((option) => (
                      <Select.Option key={option} value={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="cover_duration" label="Cover Duration">
                  <Input placeholder="Enter the duration" />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card
            title="Coverage & Benefits Selection"
            style={{ marginBottom: 24 }}
          >
            <Form.Item name="scheme_category" label="Add Scheme Category">
              <Space direction="horizontal">
                <Checkbox
                  value="Executive"
                  checked={selectedCategories.includes("Executive")}
                  onChange={handleCategoryChange}
                >
                  Executive
                </Checkbox>
                <Checkbox
                  value="Management"
                  checked={selectedCategories.includes("Management")}
                  onChange={handleCategoryChange}
                >
                  Management
                </Checkbox>
                <Checkbox
                  value="Employees"
                  checked={selectedCategories.includes("Employees")}
                  onChange={handleCategoryChange}
                >
                  Employees
                </Checkbox>
                <Checkbox
                  value="Contract"
                  checked={selectedCategories.includes("Contract")}
                  onChange={handleCategoryChange}
                >
                  Contract
                </Checkbox>
                <Checkbox
                  value="Dependants/Families"
                  checked={selectedCategories.includes("Dependants/Families")}
                  onChange={handleCategoryChange}
                >
                  Dependants/Families
                </Checkbox>
              </Space>
            </Form.Item>

            {selectedCategories.map((category) => (
              <Card
                key={category}
                title={category}
                style={{ marginBottom: 16 }}
              >
                <Row gutter={16}>
                  <Col span={6}>
                    <Form.Item
                      name={[
                        "benefits_limits_and_aggregations",
                        category,
                        "inpatient_limit",
                      ]}
                      label="Inpatient Limit"
                    >
                      <Input
                        prefix="KES"
                        placeholder="5,000,000"
                        onChange={(e) => {
                          const value = e.target.value.replace(/,/g, "");
                          if (!isNaN(value)) {
                            form.setFieldsValue({
                              benefits_limits_and_aggregations: {
                                [category]: {
                                  inpatient_limit:
                                    Number(value).toLocaleString("US"),
                                },
                              },
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={[
                        "benefits_limits_and_aggregations",
                        category,
                        "inpatient_aggregation",
                      ]}
                      label="Aggregation Level"
                    >
                      <Select placeholder="Shared Limit">
                        <Select.Option value="shared">
                          Shared Limit
                        </Select.Option>
                        <Select.Option value="non_shared">
                          Non-Shared Limit
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={[
                        "benefits_limits_and_aggregations",
                        category,
                        "outpatient_limit",
                      ]}
                      label="Outpatient Limit"
                    >
                      <Input
                        prefix="KES"
                        placeholder="1,000,000"
                        onChange={(e) => {
                          const value = e.target.value.replace(/,/g, "");
                          if (!isNaN(value)) {
                            form.setFieldsValue({
                              benefits_limits_and_aggregations: {
                                [category]: {
                                  outpatient_limit:
                                    Number(value).toLocaleString("US"),
                                },
                              },
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={[
                        "benefits_limits_and_aggregations",
                        category,
                        "outpatient_aggregation",
                      ]}
                      label="Aggregation Level"
                    >
                      <Select placeholder="Shared Limit">
                        <Select.Option value="shared">
                          Shared Limit
                        </Select.Option>
                        <Select.Option value="non_shared">
                          Non-Shared Limit
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={[
                        "benefits_limits_and_aggregations",
                        category,
                        "optical_limit",
                      ]}
                      label="Optical Limit"
                    >
                      <Input
                        prefix="KES"
                        placeholder="200,000"
                        onChange={(e) => {
                          const value = e.target.value.replace(/,/g, "");
                          if (!isNaN(value)) {
                            form.setFieldsValue({
                              benefits_limits_and_aggregations: {
                                [category]: {
                                  optical_limit:
                                    Number(value).toLocaleString("US"),
                                },
                              },
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={[
                        "benefits_limits_and_aggregations",
                        category,
                        "optical_aggregation",
                      ]}
                      label="Aggregation Level"
                    >
                      <Select placeholder="Shared Limit">
                        <Select.Option value="shared">
                          Shared Limit
                        </Select.Option>
                        <Select.Option value="non_shared">
                          Non-Shared Limit
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={[
                        "benefits_limits_and_aggregations",
                        category,
                        "dental_limit",
                      ]}
                      label="Dental Limit"
                    >
                      <Input
                        prefix="KES"
                        placeholder="100,000"
                        onChange={(e) => {
                          const value = e.target.value.replace(/,/g, "");
                          if (!isNaN(value)) {
                            form.setFieldsValue({
                              benefits_limits_and_aggregations: {
                                [category]: {
                                  dental_limit:
                                    Number(value).toLocaleString("US"),
                                },
                              },
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={[
                        "benefits_limits_and_aggregations",
                        category,
                        "dental_aggregation",
                      ]}
                      label="Aggregation Level"
                    >
                      <Select placeholder="Shared Limit">
                        <Select.Option value="shared">
                          Shared Limit
                        </Select.Option>
                        <Select.Option value="non_shared">
                          Non-Shared Limit
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={[
                        "benefits_limits_and_aggregations",
                        category,
                        "copay",
                      ]}
                      label="Co Pay"
                    >
                      <Input
                        prefix="KES"
                        placeholder="5,000,000"
                        onChange={(e) => {
                          const value = e.target.value.replace(/,/g, "");
                          if (!isNaN(value)) {
                            form.setFieldsValue({
                              benefits_limits_and_aggregations: {
                                [category]: {
                                  copay: Number(value).toLocaleString("US"),
                                },
                              },
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={[
                        "benefits_limits_and_aggregations",
                        category,
                        "service_provider_access",
                      ]}
                      label="Service Provider Access"
                    >
                      <Select mode="multiple" placeholder="Select tiers">
                        {SERVICE_PROVIDER_OPTIONS.map((option) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            ))}
          </Card>

          <Card
            title="Additional Documents & Financial Information"
            style={{ marginBottom: 24 }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="claim_report"
                  label="Claims Report (if available)"
                >
                  <Upload
                    {...uploadProps}
                    onChange={(info) =>
                      uploadProps.onChange(info, "claim_report")
                    }
                  >
                    <Button
                      icon={<UploadOutlined />}
                      loading={fileUploadLoading}
                    >
                      Click to Upload
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="client_budget" label="Client Budget">
                  <Input
                    prefix="KES"
                    placeholder="5,000,000"
                    onChange={(e) => {
                      const value = e.target.value.replace(/,/g, "");
                      if (!isNaN(value)) {
                        form.setFieldsValue({
                          client_budget: Number(value).toLocaleString("US"),
                        });
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card
            title="Sales Notes & Member Information"
            style={{ marginBottom: 24 }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="sales_notes" label="Sales Notes">
                  <TextArea
                    rows={4}
                    placeholder="Enter any special requests or additional information..."
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="member_information_template"
                  label={
                    <Space>
                      Member Information Template
                      <Typography.Link
                        href={MEMBER_TEMPLATE_URL}
                        target="_blank"
                        style={{ fontSize: "12px" }}
                      >
                        Download Template
                      </Typography.Link>
                    </Space>
                  }
                  extra="Please download and fill the template before uploading"
                >
                  <Upload
                    {...uploadProps}
                    onChange={(info) =>
                      uploadProps.onChange(info, "member_information_template")
                    }
                    accept=".xlsx,.xls,.csv"
                  >
                    <Button
                      icon={<UploadOutlined />}
                      loading={fileUploadLoading}
                    >
                      Click to Upload
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Form.Item>
            <Space style={{ float: "right" }}>
              <Button onClick={() => form.resetFields()}>Save Draft</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Request for Quote
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default ManualQuoteRequest;
