import { configureStore } from "@reduxjs/toolkit";
import quotationSlice from "./quotationSlice";
import qoutesSlice from "./qoutesSlice";
import authSlice from "./authSlice";
import leadReducer from './leadSlice';
import commissionReducer from './commissionSlice';

export const store = configureStore({
  reducer: {
    quotationSlice,
    qoutesSlice,
    authSlice,
    lead: leadReducer,
    commission: commissionReducer,
  },
});

export default store;
