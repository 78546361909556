import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../Utils/api";
import axios from "axios";

const initialState = {
  loading: false,
  error: null,
  leads: [],
  currentLead: null,
};

// Create Lead
export const createLead = createAsyncThunk(
  "lead/createLead",
  async (leadData, { dispatch }) => {
    const token = localStorage.getItem("auth-token");
    try {
      const response = await axios({
        method: "POST",
        url: `${baseUrl}/quote/lead`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: {
          business_name: leadData.name,
          nature_of_business: leadData.nature,
          size_of_company: leadData.size,
          location_of_business: leadData.location,
          contact: leadData.contact,
          email: leadData.email,
          lead_source: leadData.source,
          product: leadData.product,
        },
      });
      dispatch(getAllLeads({ page: 1, size: 10, filters: {} }));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

// Get All Leads
export const getAllLeads = createAsyncThunk(
  "lead/getAllLeads",
  async ({ page = 1, size = 20, filters = {} } = {}) => {
    console.log(filters);
    const token = localStorage.getItem("auth-token");
    const statusQuery = filters?.status ? `&status=${filters.status}` : "";
    const pageQuery = page ? `&page=${page - 1}` : "";
    const sizeQuery = size ? `&size=${20}` : "";
    try {
      const response = await axios({
        method: "GET",
        url: `${baseUrl}/quote/lead/all?${statusQuery}${pageQuery}${sizeQuery}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

// Get Lead Summary

const leadSlice = createSlice({
  name: "lead",
  initialState,
  reducers: {
    clearLeadError: (state) => {
      state.error = null;
    },
  },
  extraReducers(builder) {
    // Create Lead
    builder.addCase(createLead.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createLead.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createLead.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // Get All Leads
    builder.addCase(getAllLeads.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllLeads.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.result) {
        state.leads = action.payload.result;
      }
    });
    builder.addCase(getAllLeads.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // Get Lead Summary
  },
});

export const { clearLeadError } = leadSlice.actions;
export default leadSlice.reducer;
