import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

import styles from "./index.module.css";
import Logo from "../../../Utils/icons/eden_Care_Logo.png";
import icon_Common from "../../../Utils/icons/icon_Common.png";
import DashboardIcon from "../../../Utils/icons/pages/Dashboard.png";
import ProspectsIcon from "../../../Utils/icons/pages/Prospects.png";
import QuotesIcon from "../../../Utils/icons/pages/Quotes.png";
import ClientsIcon from "../../../Utils/icons/pages/Clients.png";
import ClaimsIcon from "../../../Utils/icons/pages/Claims.png";
import RenewalsIcon from "../../../Utils/icons/pages/Renewal.png";
import CommissionsIcon from "../../../Utils/icons/pages/Commissions.png";
import SalesToolkitIcon from "../../../Utils/icons/pages/Sales Toolkit.png";
import SupportIcon from "../../../Utils/icons/pages/Support.png";
import SettingstIcon from "../../../Utils/icons/pages/Settings.png";
import LogoutIcon from "../../../Utils/icons/pages/Logout.png";
import { WalletOutlined } from "@ant-design/icons";
import newLogo from "../../../Utils/icons/newLogo.png";
import addUser from "../../../Utils/icons/addUser.svg";
import ucare from "../../../Utils/icons/enhancedCare.png";
import audit from "../../../Utils/icons/clinetsCompanyPhoto.png"
import salesPerformance from "../../../Utils/icons/createQuoteHealthInsuranceIcon.png"

const links = [
  {
    name: "Dashboard",
    path: "/",
    icon: DashboardIcon,
    // reactIcon: <RiDashboardLine size={24} />,
  },
  {
    name: "Prospects",
    path: "/prospects",
    icon: ProspectsIcon,
  },
  {
    name: "Quotes",
    path: "/quotes",
    icon: QuotesIcon,
  },
  {
    name: "Policies",
    path: "/clients",
    icon: ClientsIcon,
  },
  // {
  //   name: "Claims",
  //   path: "/claims",
  //   icon: ClaimsIcon,
  // },
  // {
  //   name: "Renewals",
  //   path: "/renewals",
  //   icon: RenewalsIcon,
  // },
  {
    name: "Commissions",
    path: "/commissions",
    icon: CommissionsIcon,
  },
 

  {
    name:"U-Care",
    path:"/ucare",
    icon:ucare,
  },
  {
    name:"Sales Performance",
    path:"/sales-performance",
    icon:salesPerformance,
  },
  // {
  //   name: "Sales Toolkit",
  //   path: "/salestoolkit",
  //   icon: SalesToolkitIcon,
  // },
  // {
  //   name: "Support",
  //   path: "/support",
  //   icon: SupportIcon,
  // },
  // {
  //   name: "Settings",
  //   path: "/settings",
  //   icon: SettingstIcon,
  // },
  {
    name: "Audit Log",
    path: "/audit-log",
    icon: audit,
  },
  {
    name: "Users",
    path: "/users",
    icon: addUser,
  },
];

const SideBar = () => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div
      onMouseLeave={() => setOpen(false)}
      onMouseEnter={() => setOpen(true)}
      className={open ? styles.sideBarOpen : styles.sideBar}
    >
      <Link to={"/"}>
        <img src={open ? Logo : newLogo} className={styles.logo} />
      </Link>

      <div className={open ? styles.linksOpen : styles.links}>
        {links.map((item) => (
          <NavLink
            key={item.name}
            className={({ isActive }) =>
              open && isActive && item.path!=="/ucare" && item.path!=="/sales-performance" ? styles.linkItemActive : styles.linkItem
            }
            to={item.path!=="/ucare" && item.path!=="/sales-performance" && item.path}
            onClick={()=>{
              if(item.path==="/ucare"){
              window.open("https://ucare-tau.vercel.app/","_blank")
              return;
              }
              if(item.path==="/sales-performance"){
                window.open("https://sand_metabase.edeneasyclaim.com/public/dashboard/e2a23b8a-9a7c-434a-9dcf-8b6bd16c7edd","_blank")
                return;
              }
            }}
            end
          >
            {open ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "",
                  height: "24px",
                  padding: "10px",
                  width: "9rem",
                }}
              >
                        <img className={styles.sidebarIcon} src={item.icon} alt="" />

                <p
                  className={`mbZero ${styles.item_p} `}
                  style={{ wordBreak: "keep-all", whiteSpace: "initial" }}
                >
                  {item.name}
                </p>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "24px",
                  padding: "10px",
                  width: "9rem",
                }}
              >
          <img className={styles.sidebarIcon} src={item.icon} alt="" />
              </div>
            )}
          </NavLink>
        ))}
      </div>
      <div style={{ position: "absolute", bottom: "40px" }}>
        <Link
          to={"/login"}
          className={styles.linkItem}
          onClick={() => localStorage.removeItem("auth-token")}
        >
          {open ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <img className={styles.sidebarIcon} src={LogoutIcon} alt="" />

              <p
                className={`mbZero ${styles.item_p} `}
                style={{ wordBreak: "keep-all", whiteSpace: "initial" }}
              >
                {"Logout"}
              </p>
            </div>
          ) : (
            <img className={styles.sidebarIcon} src={LogoutIcon} alt="" />
          )}
        </Link>
      </div>
    </div>
  );
};

export default SideBar;
