import { lazy, Suspense } from "react";

import { Navigate, Route, Routes, useParams } from "react-router-dom";

import LoaderComponent from "../Components/Common/Loader/Loader";
import { PrivateRoute } from "./PrivateRoute";
import ManualUnderwriting from "../Pages/Manual Underwriting";

// import { PrivateRoute } from './PrivateRoute';

const NotFound = lazy(() => import("../Pages/404"));
const Main = lazy(() => import("../Components/Common/Template/Index"));
const Dashboard = lazy(() => import("../Pages/Dashboard/Index"));
const Prospects = lazy(() => import("../Pages/Prospects/Index"));
const Quotes = lazy(() => import("../Pages/Quotes/Index"));
const Clients = lazy(() => import("../Pages/Clients/Index"));
const ClientCompany = lazy(() => import("../Pages/Clients/CompanyView"));
const Claims = lazy(() => import("../Pages/Claims/Index"));
const ClaimsOverdiv = lazy(() => import("../Pages/Claims/Index"));
const Renewals = lazy(() => import("../Pages/Renewals/Index"));
const Commissions = lazy(() => import("../Pages/Commissions/Index"));
const SalesToolkit = lazy(() => import("../Pages/Sales Toolkit/Index"));
const Support = lazy(() => import("../Pages/Support/Index"));
const Settings = lazy(() => import("../Pages/Settings/Index"));
// const CreateNewQuote = lazy(() => import("../Pages/Create New Quote/Index"));
const AddNewLeads = lazy(() => import("../Pages/Add New Leads/Index"));
const Notifications = lazy(() => import("../Pages/Notifications/Index"));
const Messages = lazy(() => import("../Pages/Messages/Index"));
const Profile = lazy(() => import("../Pages/Profile/Index"));
const Login = lazy(() => import("../Pages/Login/Index"));
const Users = lazy(() => import("../Pages/Users"));
const QouteSummary = lazy(() => import("../Components/Quotes/Summary"));

const HealthInsurance = lazy(() => import("../Pages/HealthInsurance/Index"));
const ProActiv = lazy(() => import("../Pages/ProActiv/Index"));
const Health_ProActiv = lazy(() => import("../Pages/Health_ProActiv/Index"));
const Terms_Conditions = lazy(() => import("../Pages/Terms_Conditions"));
const ManualUnderwritingForm = lazy(() => import("../Pages/Manual Underwriting"));
const AuditLog = lazy(() => import("../Pages/AuditLog/Index"));

const Index = () => {
  const { policyNumber, company } = useParams();
  return (
    <Suspense fallback={<LoaderComponent />}>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />

        {/*  */}
        <Route
          path="/manual-quote"
          element={
            <PrivateRoute>
              <ManualUnderwritingForm />
            </PrivateRoute>
          }
        />

        <Route
          path="/create-quote/health-insurance"
          element={
            <PrivateRoute>
              <HealthInsurance />
            </PrivateRoute>
          }
        />
        <Route
          path="/create-quote/pro-activ"
          element={
            <PrivateRoute>
              <ProActiv />
            </PrivateRoute>
          }
        />
        <Route
          path="/create-quote/health-proactiv"
          element={
            <PrivateRoute>
              <Health_ProActiv />
            </PrivateRoute>
          }
        />

        {/*  */}

        <Route path="/login" element={<Login />} />
        <Route
          path="/prospects"
          element={
            <PrivateRoute>
              <Prospects />
            </PrivateRoute>
          }
        />
        <Route
          path="/quotes"
          element={
            <PrivateRoute>
              <Quotes />
            </PrivateRoute>
          }
        />
        <Route
          path="/quote/:qouteId"
          element={
            <PrivateRoute>
              <Quotes />
            </PrivateRoute>
          }
        />
        <Route
          path="/clients"
          element={
            <PrivateRoute>
              <Clients />
            </PrivateRoute>
          }
        />
        <Route
          path="/clients/:company"
          element={
            <PrivateRoute>
              <ClientCompany />
            </PrivateRoute>
          }
        />
        <Route
          path="/claims"
          element={
            <PrivateRoute>
              <Claims />
            </PrivateRoute>
          }
        />
        <Route
          path="/claims/:policyNumber"
          element={
            <PrivateRoute>
              <Claims />
            </PrivateRoute>
          }
        />
        <Route
          path="/renewals"
          element={
            <PrivateRoute>
              <Renewals />
            </PrivateRoute>
          }
        />
        <Route
          path="/commissions"
          element={
            <PrivateRoute>
              <Commissions />
            </PrivateRoute>
          }
        />
        <Route
          path="/salestoolkit"
          element={
            <PrivateRoute>
              <SalesToolkit />
            </PrivateRoute>
          }
        />
        <Route
          path="/support"
          element={
            <PrivateRoute>
              <Support />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />
      
        {/* <Route
          path="/createnewquote"
          element={
            <PrivateRoute>
              <CreateNewQuote />
            </PrivateRoute>
          }
        /> */}
        {/* <Route path="/addnewleads" element={<AddNewLeads />} /> */}
        {/* <Route path="/notifications" element={<Notifications />} /> */}
        {/* <Route path="/messages" element={<Messages />} /> */}
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          }
        />
        <Route
          path="/terms&conditions"
          element={
            <PrivateRoute>
              <Terms_Conditions />
            </PrivateRoute>
          }
        />
        <Route
          path="/audit-log"
          element={
            <PrivateRoute>
              <AuditLog />
            </PrivateRoute>
          }
        />

        <Route
          path="*"
          element={
            <PrivateRoute>
              <NotFound />
            </PrivateRoute>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default Index;
