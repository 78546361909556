import { Dropdown, Layout, Menu, Badge, Col, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LogoutOutlined, UserOutlined, MenuOutlined } from "@ant-design/icons";
import styles from "./index.module.css";
import messages from "../../../Utils/icons/messages.png";
import notifications from "../../../Utils/icons/notifications.png";
import demoUser from "../../../Utils/icons/demoUser.png";

const HeaderComponenet = ({ pageName, setToggleDrawer }) => {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");

  const handleMenuClick = async (e) => {
    if (e.key === "0") {
      navigate("/profile");
    }
    if (e.key === "1") {
      localStorage.removeItem("auth-token");
      navigate("/login");
    }
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: "Profile",
          key: "0",
          icon: <UserOutlined />,
        },
        {
          label: "Logout",
          key: "1",
          icon: <LogoutOutlined />,
        },
      ]}
    />
  );

  const styles = {
    color: (role) => {
      return {
        color:
          role === "Chief_Underwriter"
            ? "#B54707"
            : role === "Underwriter_Manager"
            ? "#653EBE"
            : "#B54707",
        backgroundColor:
          role === "Chief_Underwriter"
            ? "#FFFAEB"
            : role === "Underwriter_Manager"
            ? "#FAF5ZZ"
            : "#FFFAEB",
        borderRadius: "10px",
        padding: "0 10px",
        margin: "10px 0 0 -15px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        fontWeight: "500",
      };
    },
    dot: (role) => {
      return {
        backgroundColor:
          role === "Chief_Underwriter"
            ? "#B54707"
            : role === "Underwriter_Manager"
            ? "#653EBE"
            : "#B54707",
        height: "5px",
        width: "5px",
        borderRadius: "3px",
      };
    },
  };

  return (
    <div
      style={{ backgroundColor: "inherit", marginBottom: 10, marginTop: 10 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 15,
        }}>
        <h4
          className={styles.pageName}
          style={{
            fontWeight: 500,
            fontSize: 20,
            lineHeight: "30px",
            height: 30,
            width: 200,
            color: "#005B60",
            margin: "0",
          }}>
          {pageName}
        </h4>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 20,
          }}>
          {/* <Link to={"/messages"}> */}{" "}
          {/* <Badge count={2} color="#005B60">
            <img
              src={messages}
              alt=""
              style={{
                height: "20px",
                objectFit: "contain",
                cursor: "pointer",
              }}
            />
          </Badge> */}
          {/* </Link> */}
          {/* <Link to={"/notifications"}> */}{" "}
          {/* <Badge count={5} color="#005B60">
            <img
              src={notifications}
              alt=""
              style={{
                height: "20px",
                objectFit: "contain",
                cursor: "pointer",
              }}
            />
          </Badge> */}
          {/* </Link> */}
          <Dropdown overlay={menu}>
            <img
              src={demoUser}
              alt=""
              style={{
                height: "48px",
                objectFit: "contain",
                cursor: "pointer",
              }}
            />
          </Dropdown>
          <Col>
            <p style={{ margin: 0 }}>{localStorage.getItem("name")}</p>
            <p style={styles.color(role)}>
              <div style={styles.dot(role)}></div>
              {role === "Chief_Underwriter"
                ? "Underwriter Manager"
                : role === "Underwriter_Manager"
                ? "Underwriter Manager"
                : role === "Underwriter_Associate" && "Underwriter Associate"}
            </p>
          </Col>
        </div>
      </div>
    </div>
  );
};

export default HeaderComponenet;
